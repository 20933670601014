import React from 'react';
import { Typography, Box, Modal, Button } from '@mui/material';

// api

// config
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    flex: 1,
    bgcolor: 'black',
    boxShadow: 24,
    px: 3,
    py: 2,
    width: '60vw',
    border: '2px solid #ffffff',
    borderRadius: '15px'
};

const InfoModal = React.memo(({ open, onClose, body, title }) => {
    // render
    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={modalStyle}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                        sx={{
                            borderBottom: (theme) =>
                                `2px solid ${theme.palette.primary.main}`
                        }}
                        variant="h6"
                        color="secondary"
                    >
                        {title}
                    </Typography>
                    <Typography variant="p" color="secondary" mt={2} mb={1}>
                        {body}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onClose}
                        size="small"
                    >
                        Got It!
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
});

export default InfoModal;
