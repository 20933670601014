import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import axios from 'axios';

// utils
import { convertIdFieldForArray, convertIdFieldForOne } from './utils';
import { getIdToken } from '../../utils/auth';

const url = 'https://ill-defined.herokuapp.com';
// const url = 'http://localhost:5000';

const apiUrl = 'http://localhost:5000/';
const httpClient = fetchUtils.fetchJson;

const API = axios.create({ baseURL: url });
API.interceptors.request.use(async (req) => {
    const token = await getIdToken();

    if (!!token) {
        req.headers.Authorization = `Bearer ${token}`;
    }

    return req;
});

export const getList = async (resource, params) => {
    const { page, perPage } = params.pagination;
    const res = await API.get(`${resource}?page=${page}&perPage=${perPage}`);
    const data = res?.data?.data;
    const total = res?.data?.total;
    convertIdFieldForArray(data);

    return {
        data,
        total
    };
};

export const getOne = async (resource, params) => {
    const res = await API.get(`/${resource}/${params.id}`);
    const data = res?.data;
    convertIdFieldForOne(data);

    return {
        data
    };
};

export const getMany = (resource, params) => {
    const query = {
        filter: JSON.stringify({ ids: params.ids })
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
};

export const getManyReference = (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify({
            ...params.filter,
            [params.target]: params.id
        })
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
        data: json,
        total: parseInt(headers.get('content-range').split('/').pop(), 10)
    }));
};

export const create = (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
        body: JSON.stringify(params.data)
    }).then(({ json }) => ({
        data: { ...params.data, id: json.id }
    }));

export const update = async (resource, params) => {
    const res = await API.put(`/${resource}/update`, params.data);
    const data = res?.data;
    convertIdFieldForOne(data);

    return {
        data
    };
};

export const updateMany = (resource, params) => {
    const query = {
        filter: JSON.stringify({ id: params.ids })
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
        method: 'PUT',
        body: JSON.stringify(params.data)
    }).then(({ json }) => ({ data: json }));
};

export const deleteMany = (resource, params) => {
    const query = {
        filter: JSON.stringify({ id: params.ids })
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
        method: 'DELETE',
        body: JSON.stringify(params.data)
    }).then(({ json }) => ({ data: json }));
};

// eslint-disable-next-line
export default {
    getList,
    getOne,
    getMany,
    getManyReference,
    create,
    update,
    updateMany,
    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE'
        }).then(({ json }) => ({ data: json })),
    deleteMany
};
