import React, { useCallback, useState } from 'react';
import {
    Box,
    Typography,
    Button,
    CircularProgress,
    TextField
} from '@mui/material';
import useStyles from '../styles';
import { generateChords } from '../../../api';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ChordList from './ChordList';

const ERROR_MSG =
    'Invalid prompt.  Please try again or choose a different prompt.';

const ChordJamContainer = (props) => {
    // style
    const classes = useStyles();

    // selector
    const user = useSelector((state) => state.auth.authData);

    // state
    const [prompt, setPrompt] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const [error, setError] = useState(null);
    const [chords, setChords] = useState(null);

    // actions

    const onSubmit = useCallback(
        async (e) => {
            e?.preventDefault();

            setIsFetching(true);
            setError(null);

            try {
                const response = await generateChords({ prompt });
                setChords(
                    JSON.parse(
                        response?.data?.data?.choices?.[0]?.message?.content
                    )
                );
            } catch (error) {
                setError(ERROR_MSG);
                setChords(null);
            }

            setIsFetching(false);
        },
        [prompt]
    );

    // render

    if (!user) {
        return (
            <Box className="flex items-center justify-center flex-col">
                <Typography align="center" color="secondary" variant="p" mb={2}>
                    Welcome to Chord Jam! Please sign in or create a free
                    account. It's quick, easy, and absolutely free!
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/auth/chordJam"
                >
                    Login / Create Account
                </Button>
            </Box>
        );
    }

    return (
        <Box className="flex items-center justify-center">
            <form
                autoComplete="off"
                noValidate
                className="flex pt-2 px-4 flex-col items-center justify-center w-full"
                onSubmit={onSubmit}
            >
                {error && (
                    <Typography variant="p" color="primary" mb={2}>
                        {error}
                    </Typography>
                )}
                <Box className="flex w-full border-b-2 border-b-primary border-solid mb-4">
                    <Typography
                        className="text-xl text-white tracking-wider text-secondary"
                        variant="h6"
                    >
                        Chord Jam
                    </Typography>
                </Box>
                <TextField
                    name="prompt"
                    variant="filled"
                    label='Chord prompt i.e. "Give me soulful chords in D minor"'
                    fullWidth
                    value={prompt}
                    className={classes.textInput}
                    sx={{ mb: 2 }}
                    onChange={(e) => setPrompt(e.target.value)}
                />
                <Button
                    className="mb-4"
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    fullWidth
                >
                    Create Chords
                </Button>
                {isFetching && <CircularProgress sx={{ mt: 2, mb: 2 }} />}
                {!!chords && !isFetching && <ChordList chords={chords} />}
            </form>
        </Box>
    );
};

export default React.memo(ChordJamContainer);
