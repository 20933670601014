import React from 'react';
import { AppBar, IconButton } from '@mui/material';
import useStyles from './styles';
import { useLocation } from 'react-router-dom';

// icons
import MenuIcon from '@mui/icons-material/Menu';
import MainLogo from '../../images/main_logo_white.png';

const Navbar = (props) => {
    // data
    const { toggleDrawer } = props;
    const classes = useStyles();
    const location = useLocation();

    // render
    if (location?.pathname?.includes('admin')) {
        return null;
    }

    return (
        <AppBar className={classes.appBar} position="static" color="inherit">
            <div className={classes.brandContainer}>
                <IconButton
                    color="secondary"
                    aria-label="open drawer"
                    edge="start"
                    onClick={toggleDrawer}
                    sx={{ mr: 2 }}
                >
                    <MenuIcon />
                </IconButton>
                <img
                    src={MainLogo}
                    alt="ILL Tech logo"
                    className="h-auto w-40"
                />
            </div>
        </AppBar>
    );
};

export default Navbar;
