import React, { useCallback, useState } from 'react';
import {
    Box,
    Typography,
    Button,
    CircularProgress,
    TextField,
    Switch
} from '@mui/material';
import useStyles from './styles';
import { generateAiImage } from '../../api';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import InfoModal from '../common/InfoModal';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../actions/auth';

const NO_CREDIT_TITLE = 'Credit Refill Notice';
const NO_CREDIT_BODY =
    "Oops! It looks like you've run out of credits. Not to worry – your credits will automatically refill on the 1st of the month. In the meantime, you can continue to explore our platform and enjoy some of our other features. Thank you for your understanding, and we appreciate your continued support!";
const CREDIT_INFO_TITLE = 'Credits Info';
const CREDIT_INFO_BODY =
    "Great news for our free users! 🌟 Every month, you're granted 25 complimentary Image Creator credits as a token of appreciation. These credits are refreshed on the 1st of every month, giving you a fresh start to explore and enjoy our platform. New premium features and exclusive content are coming soon! We appreciate your loyalty as a user – enjoy your monthly credits!";
const ERROR_MSG =
    'Invalid prompt.  Please try again or choose a different prompt.';

const AiImageGenContainer = (props) => {
    const dispatch = useDispatch();

    // style
    const classes = useStyles();

    // selector
    const user = useSelector((state) => state.auth.authData);

    // state
    const [prompt, setPrompt] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const [error, setError] = useState(null);
    const [result, setResult] = useState(null);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [modalText, setModalText] = useState(null);
    const [isVivid, setIsVivid] = useState(true);

    // data
    const credits = user?.imageCredits;

    // actions
    const onInfoClick = useCallback(() => {
        setShowInfoModal(true);
        setModalText({
            title: CREDIT_INFO_TITLE,
            body: CREDIT_INFO_BODY
        });
    }, []);

    const onNoCredits = useCallback(() => {
        setShowInfoModal(true);
        setModalText({
            title: NO_CREDIT_TITLE,
            body: NO_CREDIT_BODY
        });
    }, []);

    const onSubmit = useCallback(
        async (e) => {
            e?.preventDefault();

            if (credits === 0 && !user?.isAdmin) {
                return onNoCredits();
            }

            setIsFetching(true);
            setError(null);

            try {
                const response = await generateAiImage({ prompt, isVivid });
                setResult(response?.data?.aiData?.data?.[0]?.url);
                dispatch(updateUser(response?.data?.user));
            } catch (error) {
                setError(ERROR_MSG);
                setResult(null);
            }

            setIsFetching(false);
        },
        [prompt, credits, onNoCredits, user, dispatch, isVivid]
    );

    const onModalClose = useCallback(() => {
        setShowInfoModal(false);
        setModalText(null);
    }, []);

    const downloadPic = () => {
        const a = document.createElement('a');
        a.href = result;
        a.target = '_blank';
        a.setAttribute('download', prompt);
        a.click();
    };

    // render

    if (!user) {
        return (
            <Box className="flex items-center justify-center flex-col">
                <Typography align="center" color="secondary" variant="p" mb={2}>
                    Welcome to our custom AI Image Generator! Please sign in or
                    create a free account. It's quick, easy, and absolutely
                    free!
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/auth/aiImageGen"
                >
                    Login / Create Account
                </Button>
            </Box>
        );
    }

    return (
        <Box className="flex items-center justify-center">
            <form
                autoComplete="off"
                noValidate
                className="flex pt-2 px-4 flex-col items-center justify-center border-2 rounded-2xl border-white border-solid w-full"
                onSubmit={onSubmit}
                style={{
                    boxShadow: '4px 4px 10px #B0B3B890'
                }}
            >
                {error && (
                    <Typography variant="p" color="primary" mb={2}>
                        {error}
                    </Typography>
                )}
                <Box className="flex w-full border-b-2 border-b-primary border-solid mb-6">
                    <Typography
                        className="text-xl text-white tracking-wider text-secondary"
                        variant="h6"
                    >
                        Image Creator
                    </Typography>
                </Box>
                <Box className="flex flex-row items-center w-full mb-2 justify-between">
                    <Box className="flex flex-row items-center">
                        <Typography variant="p" className="text-white mr-2">
                            Credits: {credits}
                        </Typography>
                        <IconButton className="p-0" onClick={onInfoClick}>
                            <InfoIcon color="secondary" sx={{ fontSize: 18 }} />
                        </IconButton>
                    </Box>
                    <Box className="flex flex-row items-center">
                        <Typography variant="p" className="text-white mr-2">
                            {isVivid ? 'Vivid' : 'Natural'} Mode
                        </Typography>
                        <Switch
                            checked={isVivid}
                            onChange={() => setIsVivid(!isVivid)}
                            sx={{
                                '& .MuiSwitch-track': {
                                    backgroundColor: '#ffffff'
                                }
                            }}
                        />
                    </Box>
                </Box>
                <TextField
                    name="prompt"
                    variant="filled"
                    label="Image Description"
                    fullWidth
                    value={prompt}
                    className={classes.textInput}
                    sx={{ mb: 1 }}
                    onChange={(e) => setPrompt(e.target.value)}
                />
                <Button
                    className="mb-4"
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    fullWidth
                >
                    Create Image
                </Button>
                {isFetching && <CircularProgress sx={{ mt: 2, mb: 2 }} />}
                {result && !isFetching && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            mb: 2,
                            width: '100%'
                        }}
                    >
                        <Typography variant="p" color="secondary" mt={2} mb={1}>
                            Result
                        </Typography>
                        <Box
                            component="img"
                            sx={{
                                height: '25vw',
                                width: '25vw',
                                borderRadius: 4
                            }}
                            alt="AI image result"
                            src={result}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={downloadPic}
                            size="small"
                            sx={{ width: '50%', mt: 2 }}
                        >
                            Download
                        </Button>
                    </Box>
                )}
            </form>
            {showInfoModal ? (
                <InfoModal
                    open={showInfoModal}
                    onClose={onModalClose}
                    body={modalText?.body}
                    title={modalText?.title}
                />
            ) : null}
        </Box>
    );
};

export default React.memo(AiImageGenContainer);
