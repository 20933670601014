import React from 'react';
import { Grid } from '@mui/material';
import ToolsListItem from './ToolsListItem';
import { isMobile } from 'react-device-detect';
import WaterIcon from '@mui/icons-material/Water';
import ImageIcon from '@mui/icons-material/Image';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

const TOOLS = [
    {
        key: 'mp3ToGif',
        display: 'MP3 To GIF Creator',
        icon: <WaterIcon fontSize="large" />,
        route: '/audioToGif'
    },
    {
        key: 'aiImageGen',
        display: 'Image Creator',
        icon: <ImageIcon fontSize="large" />,
        route: '/aiImageGen'
    },
    {
        key: 'chordJam',
        display: 'Chord Jam',
        icon: <MusicNoteIcon fontSize="large" />,
        route: '/chordJam'
    }
];

const ToolsContainer = (props) => {
    return (
        <Grid
            container
            spacing={isMobile ? 0 : 2}
            className="flex justify-center items-center"
        >
            {TOOLS.map((tool) => (
                <ToolsListItem tool={tool} />
            ))}
        </Grid>
    );
};

export default React.memo(ToolsContainer);
