import React, { useState, useCallback, useEffect } from 'react';
import { Container } from '@mui/material';
import useStyles from './styles';
import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import Auth from './components/Auth/Auth';
import Form from './components/Form/Form';
import Albums from './components/Albums/AlbumsContainer';
import MainDrawer from './components/MainDrawer';
import SingleAlbumPage from './components/Albums/SingleAlbumPage';
import AdminApp from './components/Admin';
import Tools from './components/Tools/ToolsContainer';
import GifConversionContainer from './components/Tools/GifConversionContainer';
import AiImageGenContainer from './components/Tools/AiImageGenContainer';
import ChordJamContainer from './components/Tools/ChordJamContainer';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// util
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './utils/auth';
import { getDidJustCreateAccount } from './utils/time';

// connect
import { useDispatch } from 'react-redux';
import { setUser } from './actions/auth';

const App = () => {
    // hooks
    const classes = useStyles();
    const dispatch = useDispatch();

    // state
    const [open, setOpen] = useState(false);

    // actions
    const handleDrawerToggle = useCallback(() => {
        setOpen(!open);
    }, [open]);

    // use firebase auth observer to handle user state
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const didJustCreateAccount = getDidJustCreateAccount(
                    user?.metadata?.createdAt
                );

                if (!didJustCreateAccount) {
                    dispatch(setUser(user));
                }
            }
        });
    }, [dispatch]);

    return (
        <BrowserRouter>
            <Container className={classes.mainContainer} maxwidth="lg">
                <MainDrawer open={open} toggleDrawer={handleDrawerToggle} />
                <Navbar toggleDrawer={handleDrawerToggle} />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/:title" element={<Home />} />
                    <Route path="/auth" element={<Auth />} />
                    <Route path="/auth/:route" element={<Auth />} />
                    <Route path="/newTrack" element={<Form />} />
                    <Route path="/newTrack/:id" element={<Form />} />
                    <Route path="/albums" element={<Albums />} />
                    <Route path="/album/:id" element={<SingleAlbumPage />} />
                    <Route path="/albums/:title" element={<Albums />} />
                    <Route path="/tools" element={<Tools />} />
                    <Route
                        path="/audioToGif"
                        element={<GifConversionContainer />}
                    />
                    <Route
                        path="/aiImageGen"
                        element={<AiImageGenContainer />}
                    />
                    <Route path="/admin/*" element={<AdminApp />} />
                    <Route path="/chordJam" element={<ChordJamContainer />} />
                </Routes>
            </Container>
        </BrowserRouter>
    );
};

export default App;
