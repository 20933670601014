import React, { useCallback, useEffect, useState } from 'react';
import useStyles from './styles';
import {
    Card,
    CardMedia,
    Button,
    CircularProgress,
    Typography,
    Box
} from '@mui/material';
import axios from 'axios';

// assets
import musicNote from '../../images/music_note_icon.png';

// redux
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getPost, getPostByTitle } from '../../actions/posts';

// utils
import { formatTitleForApi } from '../../utils/posts';

const SingleTrack = ({
    setShowSingleTrack,
    id,
    title,
    setTitle,
    setCurrentId
}) => {
    // hooks
    const classes = useStyles();
    const dispatch = useDispatch();
    const post = useSelector((state) => {
        return state.posts.post;
    });
    const [showGif, setShowGif] = useState(false);

    // data
    const artwork = post?.trackImage ?? musicNote;
    const gif = post?.trackGif;
    const canDownload = post?.canDownload;
    const image = showGif ? gif : artwork;

    // actions
    const downloadTrack = async () => {
        const response = await axios.get(
            post?.audioFile,
            { responseType: 'blob' } // Set responseType to 'blob' for binary data
        );
        const contentType =
            response.headers['content-type'] || 'application/octet-stream';
        const blob = new Blob([response.data], { type: contentType });

        // Create a download link and trigger the download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.setAttribute('download', post?.title);
        a.click();
    };

    const onAllTracksPress = useCallback(() => {
        setCurrentId(post?._id);
        setShowSingleTrack(false);
        !!title && setTitle(null);
    }, [setShowSingleTrack, setTitle, title, post, setCurrentId]);

    const onArtworkPress = useCallback(() => {
        setShowGif(!showGif);
    }, [showGif]);

    useEffect(() => {
        if (!!title) {
            const formattedTitle = formatTitleForApi(title);
            return dispatch(getPostByTitle(formattedTitle));
        }

        !!id && dispatch(getPost(id));
    }, [dispatch, id, title]);

    return (
        <Card className={classes.card}>
            {!post?.title ? (
                <CircularProgress />
            ) : (
                <>
                    <Button
                        onClick={onArtworkPress}
                        disabled={!gif}
                        sx={{
                            '&.Mui-disabled': {
                                backgroundColor: '#000'
                            }
                        }}
                    >
                        <CardMedia className={classes.media} image={image} />
                    </Button>
                    <Typography
                        className={classes.title}
                        variant="h6"
                        color="secondary"
                        gutterBottom
                        align="center"
                    >
                        {post?.title}
                    </Typography>
                    <Box
                        justifyContent="center"
                        flexDirection="row"
                        alignItems="center"
                    >
                        {!!canDownload && (
                            <Button
                                onClick={downloadTrack}
                                variant="contained"
                                color="primary"
                                sx={{ marginRight: 2 }}
                            >
                                Download
                            </Button>
                        )}
                        <Button
                            onClick={onAllTracksPress}
                            variant="contained"
                            color="secondary"
                        >
                            All tracks
                        </Button>
                    </Box>
                </>
            )}
        </Card>
    );
};
export default SingleTrack;
