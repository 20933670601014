// posts
export const CREATE = 'CREATE';
export const CREATE_REQUEST = 'CREATE_REQUEST';
export const CREATE_ERROR = 'CREATE_ERROR';
export const FETCH_ERROR = 'FETCH_ERROR';
export const UPDATE_ERROR = 'UPDATE_ERROR';
export const FETCH_REQUEST = 'FETCH_REQUEST';
export const UPDATE_REQUEST = 'UPDATE_REQUEST';

export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const FETCH_ALL = 'FETCH_ALL';
export const LIKE = 'LIKE';
export const FETCH_SINGLE = 'FETCH_SINGLE';
export const SET_POST = 'SET_POST';

// albums
export const FETCH_ALBUMS_REQUEST = 'FETCH_ALBUMS_REQUEST';
export const FETCH_ALBUMS_SUCCESS = 'FETCH_ALBUMS_SUCCESS';
export const FETCH_ALBUMS_ERROR = 'FETCH_ALBUMS_ERROR';
export const CREATE_ALBUMS_REQUEST = 'CREATE_ALBUMS_REQUEST';
export const CREATE_ALBUMS_SUCCESS = 'CREATE_ALBUMS_SUCCESS';
export const CREATE_ALBUMS_ERROR = 'CREATE_ALBUMS_ERROR';
export const FETCH_ALBUM_REQUEST = 'FETCH_ALBUM_REQUEST';
export const FETCH_ALBUM_SUCCESS = 'FETCH_ALBUM_SUCCESS';
export const FETCH_ALBUM_ERROR = 'FETCH_ALBUM_ERROR';
export const FETCH_ALBUM_TRACK_SUCCESS = 'FETCH_ALBUM_TRACK_SUCCESS';
export const FETCH_ALBUM_TRACK_ERROR = 'FETCH_ALBUM_TRACK_ERROR';

// users
export const AUTH = 'AUTH';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_REQUEST = 'AUTH_REQUEST';
export const LOGOUT = 'LOGOUT';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';

// tools
export const CREATE_GIF_REQUEST = 'CREATE_GIF_REQUEST';
export const CREATE_GIF_SUCCESS = 'CREATE_GIF_SUCCESS';
export const CREATE_GIF_ERROR = 'CREATE_GIF_ERROR';
