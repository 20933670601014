import React from 'react';
import { Box, Grid } from '@mui/material';

const PianoKey = ({ color, extraClass }) => (
    <Box
        className={`border-2 h-24 ${extraClass} border-solid rounded`}
        sx={{
            backgroundColor: color,
            borderColor: 'black',
            width: 'calc(100% / 7)',
            position: 'relative'
        }}
    />
);

const BlackKey = ({ left, color }) => (
    <Box
        className="absolute w-[calc(100%/14)] h-16 border-solid rounded"
        sx={{
            left,
            top: 0,
            zIndex: 1,
            backgroundColor: color
        }}
    />
);

const ChordJamChordNotes = ({ notes }) => {
    return (
        <Grid container justifyContent="start" alignItems="start">
            <Box display="flex" position="relative" className="w-60">
                {/* White Keys */}
                {['C/B#', 'D', 'E/Fb', 'F/E#', 'G', 'A', 'B/Cb'].map((note) => {
                    let isIncluded;
                    if (note?.includes('/')) {
                        const root = note?.split('/')[0];
                        const alt = note?.split('/')[1];
                        isIncluded =
                            notes?.includes(root) || notes?.includes(alt);
                    } else {
                        isIncluded = notes?.includes(note);
                    }

                    return (
                        <PianoKey
                            key={note}
                            color={isIncluded ? 'green' : 'white'}
                        />
                    );
                })}
                {/* Black Keys */}
                {['C#/Db', 'D#/Eb', '', 'F#/Gb', 'G#/Ab', 'A#/Bb', ''].map(
                    (note, index) => {
                        if (note === '') {
                            return null;
                        }

                        const sharp = note?.split('/')[0];
                        const flat = note?.split('/')[1];
                        const isIncluded =
                            notes?.includes(sharp) || notes?.includes(flat);

                        return (
                            <BlackKey
                                key={note}
                                left={`calc(100% / 7 * ${index + 1} - 0.6rem)`}
                                color={isIncluded ? 'green' : 'black'}
                            />
                        );
                    }
                )}
            </Box>
        </Grid>
    );
};

export default ChordJamChordNotes;
