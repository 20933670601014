import React from 'react';
import { Box, Typography } from '@mui/material';
import ChordJamChordNotes from './ChordJamChordNotes';

const ChordList = ({ chords }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                mb: 2,
                width: '100%'
            }}
        >
            <Typography
                className="border-b-tertiary border-t-0 border-l-0 border-r-0 border-solid"
                variant="p"
                fontSize={18}
                color="secondary"
                mt={2}
                mb={1}
            >
                Chords
            </Typography>
            <Box className="flex flex-col">
                {chords?.chords?.map((chord) => (
                    <Box className="flex flex-col" mt={1} mb={1}>
                        <Typography fontSize={16} variant="p" color="secondary">
                            {chord?.name}
                        </Typography>
                        <Box className="flex flex-row" mt={1} mb={1}>
                            <Typography
                                fontSize={16}
                                mr={1}
                                variant="p"
                                color="secondary"
                            >
                                Notes:
                            </Typography>
                            {chord?.notes?.map((note, index) => (
                                <Box className="flex flex-row">
                                    <Typography
                                        fontSize={16}
                                        mr={1}
                                        variant="p"
                                        color="secondary"
                                    >
                                        {note}
                                    </Typography>
                                    {index !== chord?.notes?.length - 1 ? (
                                        <Typography
                                            fontSize={16}
                                            mr={1}
                                            variant="p"
                                            color="secondary"
                                        >
                                            -
                                        </Typography>
                                    ) : null}
                                </Box>
                            ))}
                        </Box>
                        <Box className="flex" mt={1} mb={1}>
                            <ChordJamChordNotes notes={chord?.notes} />
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default React.memo(ChordList);
