import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
import App from './App';
import './index.css';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { mainTheme } from './themeConfig';
import { PostHogProvider } from 'posthog-js/react';

const options = {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST
};

const store = createStore(reducers, compose(applyMiddleware(thunk)));

ReactDOM.render(
    <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={options}
    >
        <Provider store={store}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={mainTheme}>
                    <App />
                </ThemeProvider>
            </StyledEngineProvider>
        </Provider>
    </PostHogProvider>,
    document.getElementById('root')
);
