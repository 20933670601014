import {
    AUTH,
    AUTH_ERROR,
    AUTH_REQUEST,
    DELETE_USER,
    LOGOUT,
    UPDATE_USER
} from '../constants/actionTypes';

const INITIAL_STATE = {
    authData: null,
    loading: false,
    error: null
};

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AUTH:
            return {
                ...state,
                loading: false,
                authData: action?.data,
                error: null
            };
        case AUTH_REQUEST:
            return {
                ...state,
                loading: true
            };
        case DELETE_USER:
        case LOGOUT:
            return {
                ...state,
                authData: null,
                loading: false,
                error: null
            };
        case AUTH_ERROR:
            return {
                ...state,
                loading: false,
                error: action?.error
            };
        case UPDATE_USER:
            return {
                ...state,
                authData: action?.data
            };
        default:
            return state;
    }
};

export default authReducer;
